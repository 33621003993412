export function storeManageFilterForm(storePersonList = [], storeTagList = []) {
  return [
    {
      labelName: "门店名称",
      key: "storeName",
      type: "input",
      placeholder: "请输入门店名称",
    },
    {
      labelName: "区域/总监",
      key: "inspectorName",
      type: "select",
      option: storePersonList,
    },
    {
      labelName: "门店标签",
      key: "tagList",
      type: "select",
      option: storeTagList,
    },
    {
      labelName: "店长姓名",
      key: "staffNameMg",
      type: "input",
      placeholder: "请输入姓名"
    },
  ]
}