<template>
  <el-dialog
    title="管理门店"
    :visible.sync="storesVisible"
    :close-on-click-modal="false"
    width="1360px"
    @close="btnStoreClose"
  >
    <GlobalForm
      v-if="showForm"
      :form-item-list="formItemList"
      :inline="true"
      confirmBtnName="查询"
      @handleConfirm="handleFilter"
    >
    </GlobalForm>
    <div style="display: flex; justify-content: space-between">
      <el-card style="width: 800px">
        <div style="font-weight: bold; height: 40px">添加门店</div>
        <el-table
          ref="leftTable"
          :data="leftShowList"
          max-height="500"
          @selection-change="(v) => (leftSelectList = v)"
        >
          <el-table-column type="selection" align="center" />
          <el-table-column label="序号" type="index" align="center" />
          <el-table-column label="区域/总监" align="center" width="80px">
            <template slot-scope="{ row }">
              <div v-if="row.areaName">
                {{ row.areaName }}
                <span v-if="row.inspectorName">/{{ row.inspectorName }}</span>
              </div>
              <div v-else-if="!row.areaName">
                {{ row.inspectorName || "--" }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="门店标签" align="center">
            <template slot-scope="{ row }">
              <div v-if="row.tagList != null">
                <el-tag v-for="item in row.tagList" :key="item.id">{{
                  item.tagName
                }}</el-tag>
              </div>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column
            label="门店店长"
            prop="staffNameMg"
            align="center"
          >
            <template slot-scope="{ row }">
              {{ row.staffNameMg }} - {{ row.mobileMg }}
            </template>
          </el-table-column>
          <el-table-column label="门店名称" prop="storeName" align="center" />
          <el-table-column label="门店启用状态" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.isEnable ? "启用中" : "已禁用" }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
      <div class="arrows-box">
        <i :disabled="!rightSelectList.length" class="arrow-btn left" @click="rightSelectList.length && storeMove('left')"/>
        <i :disabled="!leftSelectList.length" class="arrow-btn right" @click="leftSelectList.length && storeMove('right')"/>
      </div>
      <el-card
        class="box-card"
        style="width: 400px; overflow-y: auto; max-height: 580px"
      >
        <div style="font-weight: bold; height: 40px">已添加门店（{{ rightList.length }}个）</div>
        <el-table
          ref="rightTable"
          :data="rightShowList"
          max-height="500"
          @selection-change="(v) => (rightSelectList = v)"
        >
          <el-table-column type="selection" align="center" />
          <el-table-column label="序号" type="index" align="center" />
          <el-table-column
            label="门店店长"
            prop="staffNameMg"
            align="center"
          >
            <template slot-scope="{ row }">
              {{ row.staffNameMg }} - {{ row.mobileMg }}
            </template>
          </el-table-column>
          <el-table-column label="门店名称" prop="storeName" align="center" />
        </el-table>
      </el-card>
    </div>

    <span slot="footer">
      <el-button size="small" @click="btnStoreClose">关闭</el-button>
      <el-button size="small" type="primary" @click="confirm"
        >确认添加</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import _api from "@/utils/request";
import { storeManageFilterForm } from './define'
export default {
  name: "StoreManage",
  props: {
    storeList: {
      type: Array,
      default: () => [],
    },
    idList: {
      type: Array,
      default: () => [],
    },
    companyId: [String, Number]
  },
  data() {
    return {
      storesVisible: false,
      leftList: [],
      leftSelectList: [],
      rightList: [],
      rightSelectList: [],
      leftDisable: true,
      rightDisable: true,

      showForm: false,
      formItemList: storeManageFilterForm(),
      filterData: {},
      storePersonList: [],
      storeTagList: []
    };
  },
  computed: {
    leftShowList() {
      const list = this.filterTableData(this.leftList)
      console.log(list)
      return list
    },
    rightShowList() {
      return this.filterTableData(this.rightList)
    },
  },
  methods: {
    open() {
      this.init();
      // 清空筛选
      this.filterData = {}
      this.showForm = true
      this.storesVisible = true;
    },
    confirm() {
      this.$emit(
        "confirm",
        this.rightList.map((item) => item.id)
      );
      this.btnStoreClose();
    },
    btnStoreClose() {
      this.storesVisible = false;
      this.showForm = false
    },
    init() {
      // 对左右列表进行筛选
      const { storeList, idList } = this;
      const leftList = [], rightList = [];
      storeList.forEach((store) => {
        if (idList.includes(store.id)) {
          rightList.push(store);
        } else {
          leftList.push(store);
        }
      });
      this.leftList = leftList;
      this.rightList = rightList;
      // 获取筛选的下拉数据
      this.getPersonSelectList();
      this.getStoreTagsSelectList();
    },
    // 门店区域/总监下拉
    getPersonSelectList() {
      _api.inspectorAreaSelect({ companyId: this.companyId }).then((res) => {
        if (res.code === 1) {
          const storePersonList = res.data;
          storePersonList.forEach(item => {
            item.label = [item.areaName, item.staffName].filter(item => item).join("-")
            item.value = [item.areaId, item.staffId].join('-')
          })
          this.storePersonList = storePersonList
          this.setForm()
        }
      });
    },
    // 门店标签下拉
    getStoreTagsSelectList() {
      _api.getStoreTagSelect({ companyId: this.companyId }).then((res) => {
        if (res.code === 1) {
          const storeTagList = res.data;
          storeTagList.forEach(item => {
            item.label = item.name
            item.value = item.id
          })
          this.storeTagList = storeTagList
          this.setForm()
        }
      });
    },
    setForm() {
      this.$nextTick(() => {
        this.formItemList = storeManageFilterForm(this.storePersonList, this.storeTagList)
      })
    },
    handleFilter(v) {
      // form数据处理
      const filterData = { ...v }
      const { inspectorName } = filterData
      const [areaId, inspectorId] = inspectorName.split('-')
      filterData.areaId = areaId
      filterData.inspectorId = inspectorId
      delete filterData.inspectorName
      this.filterData = filterData
    },
    filterTableData(list) {
      return list.filter(item => {
        let isTrue = true
        Object.entries(this.filterData).forEach(([key, value]) => {
          if (!isTrue) return
          if (value) {
            if (!item[key]) {
              isTrue = false
            } else if (['storeName', 'staffNameMg'].includes(key)) {
              if (item[key].indexOf(value) === -1) isTrue = false
            } else if (['areaId', 'inspectorId'].includes(key)) {
              if (item[key] != value) isTrue = false
            } else if (key === 'tagList') {
              if (item[key].findIndex(d => d.id == value) == -1) {
                isTrue = false
              }
            }
          }
        })
        return isTrue
      })
    },
    selectChange(type, value) {
      const disable = !(value && value.length);
      if (type === "left") this.rightDisable = disable;
      if (type === "right") this.leftDisable = disable;
    },
    storeMove(type) {
      const selectList =
        type === "left" ? this.rightSelectList : this.leftSelectList;
      const selectIds = selectList.map((item) => item.id);
      if (type === "right") {
        this.rightList.push(...selectList);
        this.leftList = this.leftList.filter(
          (item) => !selectIds.includes(item.id)
        );
      } else {
        this.leftList.push(...selectList);
        this.rightList = this.rightList.filter(
          (item) => !selectIds.includes(item.id)
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.arrows-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  .arrow-btn {
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 2px 6px 6px 0px rgba(102,102,102,0.16);
    &[disabled] {
      cursor: not-allowed;
      &.left {
        background: url('../../../assets/images/storeshop/icon_hs_zj.png');
      }
      &.right {
        background: url('../../../assets/images/storeshop/icon_hs_yj.png');
      }
    }
    &.left{
      background: url('../../../assets/images/storeshop/icon_qhs_zj.png');
    }
    &.right{
      margin-top: 16px;
      background: url('../../../assets/images/storeshop/icon_ls_yj.png');
    }
  }
}
</style>